import Axios from 'axios'
import Vue from 'vue'
import {GCONF} from '../../public/GCONF'
// import { goLogin } from '@/common/js/bom'
console.log(process.env.NODE_ENV)

// let baseURL = process.env.NODE_ENV=='development'?'/zuul': window.baseAPI.baseURL
// console.log(baseURL) 环境判断暂时无用
const service = Axios.create({
  // api的base_url
  //baseURL: `${GCONF.SERVICE.MT_SERVICE_BASE_PATH}${GCONF.SERVICE.MT_SERVICE_NAME_SPACE}`,
  baseURL: '/zuul',
  // 设置请求超时时间30s
  timeout: 30000
})

service.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
service.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
service.defaults.headers['tenantid'] = 1
service.defaults.headers['envId'] = '5bd572143ee63812c092bcc658b4c17a'
service.defaults.validateStatus = null

const pending = {}
// const CancelToken = Axios.CancelToken
const removePending = (key, isRequest = false) => {
  if (pending[key] && isRequest) {
    pending[key]('取消重复请求')
  }
  delete pending[key]
}
const getRequestIdentify = (config, isRequest) => {
  let url = config.url
  if (isRequest) {
    url = config.baseURL + url.substring(1, url.length)
  }
  return config.method === 'get'
    ? encodeURIComponent(url + JSON.stringify(config.params))
    : encodeURIComponent(url + JSON.stringify(config.data))
}
// 添加请求拦截器
service.interceptors.request.use(
  function(config) {
    if (config.url === '/call') {
      config.url = `${GCONF.SERVICE.MT_SERVICE_NAME_SPACE}${config.url}`
    }
    // 拦截取消正在进行的重复请求
    const identify = getRequestIdentify(config, true)
    removePending(identify, true)
    // config.cancelToken = new CancelToken(c => {
    //   pending[identify] = c
    // })
    // const token = Vue.prototype.getCookie('access_token')
    // const token = localStorage.getItem('access_token')
    // if (token) {
    //   config.headers.Authorization = 'Bearer ' + token
    //   config.headers.tenantId = localStorage.getItem('tenantId')
    // }
    // 在发送请求之前做些什么
    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function(response) {
    const identify = getRequestIdentify(response.config, false)
    removePending(identify)
    const flag = validateStatus(response)
    if (!flag) exceptionHandler(response)
    // 对响应数据做点什么
    return response
  },
  function(err) {
    // 对响应错误做点什么
    if (err && err.response) {
      const identify = getRequestIdentify(err.response.config, false)
      removePending(identify)
      const flag = validateStatus(err.response)
      if (!flag) exceptionHandler(err.response)
    }
  }
)

// 校验状态
function validateStatus(response) {
  return response.data && (response.data.code === 0 || response.data.code === 200 || response.data.Flag)
}

function authFailHandler() {
  // UserModule.ResetUser()
  // const router = Vue.prototype.$router
  // const route = Vue.prototype.$route
  // router
  //   .push(`/login?redirect=${route.fullPath}`)
  //   .catch((err) => {
  //     console.warn(err)
  //   })
  // goLogin()
}

// 异常处理
function exceptionHandler(response) {
  if (response.status < 200 || response.status > 300) {
    let message = response.data ? response.data.message || response.data.ErrInfo : ''
    if (!message) {
      switch (response.status) {
        case 400:
          message = '错误请求'
          break
        case 401:
          message = '未登录或登录信息已过期，请重新登录'
          break
        case 403:
          message = '拒绝访问'
          break
        case 404:
          message = '请求错误,路径错误'
          break
        case 405:
          message = '请求方法未允许'
          break
        case 408:
          message = '请求超时'
          break
        case 500:
          message = '服务器端出错'
          break
        case 501:
          message = '网络未实现'
          break
        case 502:
          message = '网络错误'
          break
        case 503:
          message = '服务不可用'
          break
        case 504:
          message = '网络超时'
          break
        case 505:
          message = 'http版本不支持该请求'
          break
        default:
          message = `连接错误${response.status}`
      }
    }
    if (response.status === 401) {
      authFailHandler()
    } else {
      Vue.prototype.$message.error(message || response.statusText)
    }
  }
}

export default service
