import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './http'
import pxiel from './pxiel'

import 'normalize.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

pxiel.init()

Vue.prototype.http = http
Vue.prototype.$globalConfig = {
  adaptation: window.innerWidth > 750 ? true : false
}

Vue.prototype.bgImg = (src) => {
  return { background: `url(${src})  no-repeat`, backgroundSize: 'cover' }
}

/* 设置标题 */
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = binding.value
  },
  componentUpdated: function (el, binding, vnode) {
    document.title = binding.value
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// const routeNames = router.options.routes.map(item => item.name)
// const mobile = routeNames.filter(item => item.substring(0, 6) === 'Mobile')
// const pc = routeNames.filter(item => item.substring(0, 6) !== 'Mobile')
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)
//   if (window.innerWidth <= 750 && pc.indexOf(to.name) !== -1) {
//     next('/mobileHome')
//   } else if (window.innerWidth > 750 && mobile.indexOf(to.name) !== -1) {
//     next('/')
//   } else {
//     next()
//   }
// })
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

window.onbeforeunload = function () {
  window.scrollTo(0, 0)
}

window.onresize = function () {
  pxiel.init()
}
