export default {
  rightListData: [
    {
      title: '解决方案',
      data: [
        {
          text: '医药生产'
        }, {
          text: '医药流通'
        }, {
          text: '医药连锁'
        }, {
          text: '医药三方'
        }, {
          text: '酒水饮料'
        }, {
          text: '食品生鲜'
        }, {
          text: '美妆日化'
        },
      ]
    },

    {
      title: '产品服务',
      data: [
        {
          text: '物流中心集成'
        }, {
          text: '供应链物流软件'
        }, {
          text: '物流智能装备'
        }, {
          text: '物流运营管理'
        }
      ]
    },
    {
      title: '云平台',
      data: [
        {
          text: 'aPaaS平台'
        }, {
          text: '核心价值'
        }, {
          text: '主要功能'
        }, {
          text: '服务客户'
        }
      ]
    },
    {
      title: '案例中心',
      data: [
        {
          text: '医药行业案例'
        }, {
          text: '酒水行业案例'
        }, {
          text: '食品行业案例'
        }, {
          text: '更多其他案例'
        }
      ]
    },
    {
      title: '新闻资讯',
      data: [
        {
          text: '公司新闻'
        }, {
          text: '行业新闻'
        }
      ]
    },
  ],
  linkList: [
    {
      text: '九州通医药集团',
      link: 'https://www.jztey.com/'
    },
    {
      text: '九州通物流',
      link: 'http://www.jzt56.com/'
    },
    {
      text: '九州通健康科技',
      link: 'https://about.jk.com/'
    },
    {
      text: '九州通医投',
      link: 'http://www.jztyltz.com/'
    },
    {
      text: '物合网',
      link: 'http://www.cloud56.net/'
    },
    {
      text: '物道网',
      link: 'http://www.56dao.com/'
    },
  ],
  imgList: [
    {
      icon: require('/public/static/image/home_new/foot_img_1.png'),
      img: require('/public/static/image/home_new/foot_icon_1.png'),
    },
    {
      icon: require('/public/static/image/home_new/foot_img_2.png'),
      img: require('/public/static/image/home_new/foot_icon_2.png'),
    },
    {
      icon: require('/public/static/image/home_new/foot_img_3.png'),
      img: require('/public/static/image/home_new/foot_icon_3.png'),
    },
  ]
}
