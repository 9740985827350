import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { // 首页
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  { // 公司简介
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  //物流集成
  {
    path: '/logisInte',
    name: 'LogisIntegra',
    component: () => import('@/views/LogisIntegra.vue')
  },
  //信息系统
  {
    path: '/infoTechlo',
    name: 'InfoTechlo',
    component: () => import('@/views/InfoTechlo.vue')
  },
  //智能装备
  {
    path: '/intellectDevice',
    name: 'IntellectDevice',
    component: () => import('@/views/IntellectDevice.vue')
  },
  //云市场
  {
    path: '/cloudMarket',
    name: 'CloudMarket',
    component: () => import('@/views/CloudMarket.vue')
  },
  //开发者
  {
    path: '/developer',
    name: 'Developer',
    component: () => import('@/views/Developer.vue')
  },
  //新闻
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue')
  },
  //新闻
  {
    path: '/news-details',
    name: 'news-details',
    component: () => import('@/views/newsDetails.vue')
  },
  //新闻
  {
    path: '/case-details',
    name: 'case-details',
    component: () => import('@/views/newsDetails.vue')
  },
  //招聘
  {
    path: '/recruit',
    name: 'Recruit',
    component: () => import('@/views/Recruit.vue')
  },
  //云平台
  {
    path: '/cloudPlat',
    name: 'cloudPlat',
    component: () => import('@/views/cloudPlat.vue')
  },
  //案例中心
  {
    path: '/caseCenter',
    name: 'caseCenter',
    component: () => import('@/views/caseCenter.vue')
  },
  {
    path: '/recruitDetail',
    name: 'RecruitDetail',
    component: () => import('@/views/RecruitDetail.vue')
  },
  {
    path: '/resolvePlan/:id',
    name: 'resolvePlan',
    component: () => import('@/views/resolvePlan.vue')
  },
  {
    path: '/productServe',
    name: 'productServe',
    component: () => import('@/views/productServe.vue')
  }, {
    path: '/applicationUse',
    name: 'applicationUse',
    component: () => import('@/views/applicationUse.vue')
  },
  //  移动端 申请试用
  {
    path: '/applicationUseMobile',
    name: 'MobileApplicationUseMobile',
    component: () => import('@/views/applicationUseMobile.vue')
  },
  // // 解决方案 - 医药生成
  // {
  //   path: '/medicine',
  //   name: 'Medicine',
  //   component: () => import('@/views/resolvePlan/Medicine.vue')
  // },
  // { // 解决方案 - 医药流通
  //   path: '/medicineCycle',
  //   name: 'MedicineCycle',
  //   component: () => import('@/views/resolvePlan/MedicineCycle.vue')
  // },
  // { // 解决方案 - 医药连锁
  //   path: '/medicineChain',
  //   name: 'MedicineChain',
  //   component: () => import('@/views/resolvePlan/MedicineChain.vue')
  // },
  // { // 解决方案 - 医疗器械
  //   path: '/medicineInstru',
  //   name: 'MedicineInstru',
  //   component: () => import('@/views/resolvePlan/MedicineInstru.vue')
  // },
  // { // 解决方案 - 食品生鲜
  //   path: '/foods',
  //   name: 'Foods',
  //   component: () => import('@/views/resolvePlan/Foods.vue')
  // },
  // { // 解决方案 - 三方物流
  //   path: '/threeLogis',
  //   name: 'ThreeLogis',
  //   component: () => import('@/views/resolvePlan/ThreeLogis.vue')
  // },
  { // 手机端产品与服务
    path: '/mobileProductService',
    name: 'MobileProductMain',
    component: () => import('@/components/mobile/mobile-product-service/index.vue')
  },
  { // 手机端产品与服务详情
    path: '/mobileProductServiceDetail/:id',
    name: 'MobileProductServiceDetail',
    component: () => import('@/components/mobile/mobile-product-service/mobile-product-service-detail.vue')
  },
  { // 手机端招聘
    path: '/mobileRecruit',
    name: 'MobileRecruit',
    component: () => import('@/components/mobile/mobile-recruit/index.vue')
  },
  { // 手机端招聘详情
    path: '/mobileRecruitDetail',
    name: 'MobileRecruitDetail',
    component: () => import('@/components/mobile/mobile-recruit/mobile-recruit-detail.vue')
  },
  { // 手机端解决方案
    path: '/mobileSolution',
    name: 'MobileSolutionMain',
    component: () => import('@/components/mobile/mobile-solution/index.vue')
  },
  { // 手机端解决方案详情
    path: '/mobileSolutionDetail/:id',
    name: 'MobileSolutionDetail',
    component: () => import('@/components/mobile/mobile-solution/mobile-solution-detail.vue')
  },
  { // 手机端企业介绍
    path: '/mobileCompanyIntroduction',
    name: 'MobileCompanyIntroduction',
    component: () => import('@/components/mobile/mobile-company-introduction/index.vue')
  },
  { // 手机端SAAS云服务
    path: '/mobileCloudPlatform',
    name: 'MobileCloudPlatformMain',
    component: () => import('@/components/mobile/mobile-cloud-platform/index.vue')
  },
  { // 手机端SAAS云服务详情页
    path: '/mobileCloudPlatformDetail/:id',
    name: 'MobileCloudPlatformDetail',
    component: () => import('@/components/mobile/mobile-cloud-platform/mobile-cloud-platform-detail.vue')
  },
  { // 手机端开发者平台
    path: '/mobileDeveloper',
    name: 'MobileDeveloperMain',
    component: () => import('@/components/mobile/mobile-developer/index.vue')
  },
  { // 手机端开发者平台详情页
    path: '/mobileDeveloperDetail/:id',
    name: 'MobileDeveloperDetail',
    component: () => import('@/components/mobile/mobile-developer/mobile-developer-detail.vue')
  },
  { // 手机端首页
    path: '/mobileHome',
    name: 'MobileHome',
    // redirect: { name: 'MobileApplicationUseMobile' },  //  暂时 重定向
    component: () => import('@/components/mobile/mobile-home/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
