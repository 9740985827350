export const resolvePlanData = [
	{
		title: '医药生产',
		des: '生产物流与流通物流软硬件一体集成方案',
		path: '/resolvePlan/1',
		mainContent: {
			name: '医药生产',
			floor1: {
				title: '医药生产物流方案特点',
				listData: [
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '采购、生产、流通物流一体化集成，',
						text2: '全过程透明监管、追溯及预警'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '实现GMP与作业流程融合，',
						text2: '全过程闭环监控'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '智能调度与流程协同，降低人',
						text2: '工依赖，提升时效性与准确性'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '提供全供应链GMS、FDA数据管控，',
						text2: '实现质量管理线上化与标准化'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '集成高度智能化装备实现标准化，',
						text2: '高效率，高准确、高吞吐能力'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '实时数据监控分析，为运营管理',
						text2: '提供决策支持，提高运营质量'
					}
				]
			},
			floor2: {
				bg: require('@/assets/image/resolvePlan/f2_bg_1new.png'),
				programme: require('@/assets/image/resolvePlan/programme1.png'),
				title: '医药生产全场景数智物流解决方案',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/systemimage1.png'),
						text1: 'OFS',
						text2: '协同管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage2.png'),
						text1: 'WMS',
						text2: '仓储管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage3.png'),
						text1: 'TMS',
						text2: '运输管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage4.png'),
						text1: 'EIS',
						text2: '设备集成系统'
					}
				]
			},
			floor3: {
				title: '方案优势',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_11.png'),
						text1: '生产、流通物流一套系统集成',
						text2: '大幅提升运营效率'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_12.png'),
						text1: '物流数据实时集中',
						text2: '全景数字化管控'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_13.png'),
						text1: '质量管理与作业流程',
						text2: '融合全过程闭环监控'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_14.png'),
						text1: '高度智能化装备',
						text2: '实现高效率与高吞吐作业'
					}
				]
			},
			floor4: {
				title: '客户案例',
				imgSrc: require('@/assets/image/resolvePlan/f4_1.png'),
				text1: '河南亚都：打造一体化现代医药生产智能仓库',
				msgBoxList: [
					{
						label: '项目方案',
						content: '负责集成总包工作，提供总体设计、规划布局、流程设计、细部设计、设备招标选型与安装调试、现场管理、系统设计与开发、模拟运行、物流中心整体搬迁、系统上线等全套的物流解决方案。'
					},
					{
						label: '项目内容',
						content: '该项目硬件投入双伸位、单伸位立体仓库、多层穿梭车，配合WMS软件、WCS软件实现上下游信息连通共享提效率、原辅料精益过程管理、全流程系统质量管控防差错，采销、仓储、生产连通促协同。'
					},
					{
						label: '项目成果',
						type: '1',
						listData: [
							{
								text1: '60%',
								text2: '效率提升',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '25%',
								text2: '人工费用减少',
								icon: require('@/assets/image/resolvePlan/f4_icon_down.png')
							},
							{
								text1: '5-10年',
								text2: '满足业务需求'
							}
						]
					}
				]
			},
			floor6: {
				title: '医药生产项目案例',
				projectlist: [
					{
						id: 0,
						img: require('@/assets/image/resolvePlan/zhongs.png'),
						title: '广东众生',
						info: '九州云智为广东众生药业提供整体集成总包服务。主要为中药原材料的收货、存储、缓存、分料、转运做定制化解决方案。硬件投入包含智能AGV、拆码垛机械手、助力臂、分料机及各类定制化夹具，配合WMS、EIS软件系统实现从原材料入库、净药材前处理、暂存、投料的全流程自动化、无人化，真正实现数字化工厂产线。'
					},
					{
						id: 1,
						img: require('@/assets/image/resolvePlan/yadu.png'),
						title: '河南亚都',
						info: '九州云智为河南亚都集团提供旧仓改造和新仓新建的一体式物流集成服务。包含库房规划布局、流程设计、设备选型与安装调试、现场管理、系统设计与开发、模拟运行、物流中心整体搬迁、系统上线等全套的物流解决方案。软硬件涵盖自动化立体库、自动化输送分拣设备、WMS、EIS等信息系统。'
					},
					{
						id: 2,
						img: require('@/assets/image/resolvePlan/tuoren.png'),
						title: '河南驼人',
						info: '九州云智为河南驼人公司提供全套集成总包服务。包含自动化立体库、自动化分拣输送设备安装调试上线、WMS库存系统、EIS控制系统实施等仓储集成技术服务，并与生产环节衔接，全局调度、全流程信息化，优化任务策略，大幅提升订单作业同步性、保障订单出库效率。'
					}
				]
			}
		}
	},
	{
		title: '医药流通',
		des: '多仓多货主平台化模式，兼容多业态应用场景',
		path: '/resolvePlan/2',
		mainContent: {
			name: '医药流通',
			floor1: {
				title: '医药流通物流方案特点',
				listData: [
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '全方位GSP质量管理体系',
						text2: '质量数据可查，质量安全可控'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '全国多层级网络协同，一体化满足多',
						text2: '业主、多业态、多品种的业务需求'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '柔性智能作业场景，降低人工依',
						text2: '赖，高效率、高准确、高扩展性'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '智能调度与同步性控制，实现',
						text2: '资源最大化利用与高吞吐量'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '全图形可视化的配送过程管理',
						text2: '全环节实时监管与主动预警防范'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '一体化物流信息管理集成平台',
						text2: '全过程应用系统覆盖、全数字化运营'
					}
				]
			},
			floor2: {
				bg: require('@/assets/image/resolvePlan/f2_bg_2.png'),
				programme: require('@/assets/image/resolvePlan/programme3.png'),
				title: '医药流通全场景数智物流解决方案',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/systemimage1.png'),
						text1: 'OFS',
						text2: '协同管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage2.png'),
						text1: 'WMS',
						text2: '仓储管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage3.png'),
						text1: 'TMS',
						text2: '运输管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage4.png'),
						text1: 'EIS',
						text2: '设备集成系统'
					}
				]
			},
			floor3: {
				title: '方案优势',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_21.png'),
						text1: '全方位GSP质量管理体系',
						text2: '质量数据可查、质量安全可控'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_22.png'),
						text1: '多仓多货主平台化模式',
						text2: '高度可配置、高度兼容多业态应用场景'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_23.png'),
						text1: '柔性智能作业场景、降低人工依赖',
						text2: '高效率、高准确、高扩展性'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_24.png'),
						text1: '智能调度与同步性控制',
						text2: '实现资源最大化利用与高吞吐量'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_25.png'),
						text1: '全图形可视化的配送过程管理',
						text2: '全环节实时监管与主动预警防范'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_26.png'),
						text1: '一体化物流信息管理集成平台',
						text2: '全过程应用系统覆盖、全数字化运营'
					}
				]
			},
			floor4: {
				title: '客户案例',
				imgSrc: require('@/assets/image/resolvePlan/f4_2.png'),
				text1: '山东健康：特大型现代高端智慧医药仓储物流中心',
				msgBoxList: [
					{
						label: '项目方案',
						content: '总体设计到整体搬迁、上线的全套集成总包服务。包含立体库、自动化分拣、月台AGV、料箱机器人、机械臂、输送线安装调试上线，整体ERP、WMS、WCS、TMS等系统实施的仓储集成技术服务。'
					},
					{
						label: '项目内容',
						content: '物流中心设计高密度储存货架、箱式输送线、螺旋输送机、入库托盘提升机、自动分拣复核系统、PDA无线手持终端、电子标签辅助拣选、多料箱拣选机器人等多种现代化、自动化的物流设施设备。同时提供量身定制的WMS、WCS、TMS等物流管理系统和软件控制系统，集成自动化设备控制和智能调度的管理，进行智能化+信息化管理的集合。'
					},
					{
						label: '项目成果',
						type: '1',
						listData: [
							{
								text1: '60%',
								text2: '月台能力提高',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '25%',
								text2: '拣选效率提高',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '3倍',
								text2: '库容提升',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							}
						]
					}
				]
			},
			floor6: {
				title: '医药流通项目案例',
				projectlist: [
					{
						id: 0,
						img: require('@/assets/image/resolvePlan/henanjzt.png'),
						title: '河南九州通',
						info: '九州云智为河南九州通医药公司提供从总体设计到整体搬迁、上线的全套集成总包服务。包括立体库、机械手、多层穿梭车、AGV、高速分拣等智能装备集成与安装调试、同时提供量身定制OFS、WMS、TMS、BMS等软件平台设计与开发，一体化满足2B、2C客户，共150亿业务规模。'
					},
					{
						id: 1,
						img: require('@/assets/image/resolvePlan/sdjk.png'),
						title: '山东健康',
						info: '九州云智为山东健康集团提供一体化集成总包服务。包括堆垛机立体库、自动化分拣输送、月台集货AGV、多料箱机器人、拆码垛机械臂等自动化设备的选型及安装调试，定制化开发ERP、WMS、EIS、TMS等信息系统的仓储集成技术服务。'
					},
					{
						id: 2,
						img: require('@/assets/image/resolvePlan/ribenxy.png'),
						title: '日本新药',
						info: '九州云智为艾努爱世医药公司打造了一座智能化医药物流中心，满足其业务、作业、运营、技术一体化应用场景票求。包含托盘穿梭车立体库、智能AGV货到人拣选、自动化分拣输送线等设备的安装调试上线、WMS库存系统的定制开发与实施等相关仓储集成技术服务。'
					}
				]
			}
		}
	},
	{
		title: '医药连锁',
		des: '订单全生命周期的实时跟踪，可视化运营',
		path: '/resolvePlan/3',
		mainContent: {
			name: '医药连锁',
			floor1: {
				title: '医药连锁物流方案特点',
				listData: [
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '上下游仓配一体化协同管理，',
						text2: '实现数据无缝衔接'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '数据支撑决策，全程监控、预警，',
						text2: '全环节透明可视，准确率99.99%以上'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '采用大分拨模式',
						text2: '单人拣选效率350条以上'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '通过多波次、多任务的组合算法',
						text2: '提升作业命中率，进而大幅提升分拣效率'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '采用柔性的智能化设备及灵活',
						text2: '可扩展的软件平台，灵活应对峰值作业'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '订单全生命周期的实时跟踪，',
						text2: '精细物流费用核算、作业流程标准化'
					}
				]
			},
			floor2: {
				bg: require('@/assets/image/resolvePlan/f2_bg_3.png'),
				programme: require('@/assets/image/resolvePlan/programme4.png'),
				title: '医药连锁全场景数智物流解决方案',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/systemimage1.png'),
						text1: 'OFS',
						text2: '协同管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage2.png'),
						text1: 'WMS',
						text2: '仓储管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage3.png'),
						text1: 'TMS',
						text2: '运输管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage4.png'),
						text1: 'EIS',
						text2: '设备集成系统'
					}
				]
			},
			floor3: {
				title: '方案优势',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_31.png'),
						text1: '上游供应商、下游门店',
						text2: '仓配一体化协同管理'
					},
					// {
					//   icon: require("@/assets/image/resolvePlan/f3_icon_32.png"),
					//   text1: '数据集中',
					//   text2: '实时融合运算、全程可视化'
					// },
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_33.png'),
						text1: '质量及商品品控与作业流程融合',
						text2: '全过程闭环管理'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_34.png'),
						text1: '高度智能化装备',
						text2: '实现具备柔性的峰值应对能力'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_35.png'),
						text1: '智能调度降低人工依赖',
						text2: '时效性与准确性提高'
					}
				]
			},
			floor4: {
				title: '客户案例',
				imgSrc: require('@/assets/image/resolvePlan/f4_3.png'),
				text1: '中山中智：打造国内首个CTU医药仓储管理平台',
				msgBoxList: [
					{
						label: '项目方案',
						content: '交钥匙集成服务项目。负责总装自动化仓库及分拣配送系统设备的设计、制造、安装、调试、培训和售后服务等。'
					},
					{
						label: '项目内容',
						content: '物流中心设计高密度储存货架、箱式输送线、螺旋输送机、入库托盘提升机、自动分拣复核系统、PDA无线手持终端、电子标签辅助拣选、多料箱拣选机器人等多种现代化、自动化的物流设施设备。同时提供量身定制的WMS、WCS、TMS等物流管理系统和软件控制系统，集成自动化设备控制和智能调度的管理，进行智能化+信息化管理的集合。'
					},
					{
						label: '项目成果',
						type: '1',
						listData: [
							{
								text1: '200%',
								text2: '存储密度提高',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '2.5倍',
								text2: '作业效率提升',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '多业态',
								text2: '运营模式'
							},
							{
								text1: '全链路',
								text2: '精准监控'
							}
						]
					}
				]
			},
			floor6: {
				title: '医药连锁项目案例',
				projectlist: [
					{
						id: 0,
						img: require('@/assets/image/resolvePlan/zszz.png'),
						title: '中山中智',
						info: '九州云智为中山中智大药房提供交钥匙集成服务。负责从物流规划设计，到CTU、高度分拣、螺旋机等设备总装、调试和售后服务，并提供量身定制的WMS、EIS、TMS等物流管理系统和软件控制系统，进行“智能化+信息化”管理的集合。'
					},
					{
						id: 1,
						img: require('@/assets/image/resolvePlan/qdybc.png'),
						title: '青岛医保城',
						info: '九州云智为青岛医保城医药连锁公司提供全套集成总包服务，量身定制整体解决方案，涵盖软件管理系统WMS、EIS、TMS、OFS等，以及集成托盘穿梭车、料箱穿梭车月台集货AGV等自动化设备，实现仓储、运输、拣选、配送等各环节作业的高效运行。'
					},
					{
						id: 2,
						img: require('@/assets/image/resolvePlan/gddsl.png'),
						title: '广东大参林',
						info: '九州云智为大参林医药集团提供物流软件平台，包括OFS模块、TMS模块、IOT物联模块、OAS运营考核模块。进行物流中心的配送过程、运力、考核的集中管理，打通内外部接口、配套内部运营管理流程的设计、开发和实施，以及集成调试上线等服务。'
					}
				]
			}
		}
	},
	{
		title: '医药三方',
		des: '聚焦3PL“仓、运、配”环节，智能的路由',
		path: '/resolvePlan/4',
		mainContent: {
			name: '医药三方',
			floor1: {
				title: '三方物流供应链物流挑战',
				listData: [
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '客户类型多样化、作业流程多样化',
						text2: '物流网络的构建、库存布局与资源配置复杂'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '订单任务计划性较差，客户要求多样化',
						text2: '任务调度在考虑成本与服务效率的平衡'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '作业流程长、中间交接环节多',
						text2: '多组织任务协同及质量协同与监管难度大'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '不同类型客户核算方式多样化，物流成本',
						text2: '与费用核算模型复杂，数据完整性要求高'
					}
				]
			},
			floor2: {
				bg: require('@/assets/image/resolvePlan/f2_bg_6.png'),
				programme: require('@/assets/image/resolvePlan/programme5.png'),
				title: '三方物流全场景数智物流解决方案',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/systemimage1.png'),
						text1: 'OFS',
						text2: '协同管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage2.png'),
						text1: 'WMS',
						text2: '仓储管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage3.png'),
						text1: 'TMS',
						text2: '运输管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage4.png'),
						text1: 'EIS',
						text2: '设备集成系统'
					}
				]
			},
			floor3: {
				title: '方案优势',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_61.png'),
						text1: '上下游互联互通，全过程透明服务体验'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_62.png'),
						text1: '全流程应用覆盖与全景数字化运营'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_63.png'),
						text1: '构建多层次物流网络与一体化协同运作'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_64.png'),
						text1: '智能计划管理与任务调度'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_65.png'),
						text1: '智能装备赋能物流高效安全作业'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_66.png'),
						text1: '精益的成本核算与费用管理'
					}
				]
			},
			floor4: {
				title: '客户案例',
				imgSrc: require('@/assets/image/resolvePlan/f4_6.png'),
				text1: '康展物流：建设多层级、多层次的协同物流网络',
				msgBoxList: [
					{
						label: '项目方案',
						content: '建设总部、区域、网点三个层次的协同物流网络， 对接客户系统，统一接入订单，智能任务调度，分层执行。标准化、系统化物流全流程作业，精细的核算物流成本与费用。实现物流全过程实时监控与追溯，自动KPI考核数据分析与统计。'
					},
					{
						label: '项目内容',
						content: '上线物流云平台，包含官网、公众号、OMS订单与调度管理、TMS运输管理、CTS冷链管理、BMS计费管理、OAS分析决策及IOT物联系统。'
					},
					{
						label: '项目成果',
						type: '1',
						listData: [
							{
								text1: '85%',
								text2: '订单效率提升',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '45%',
								text2: '协同效率提升',
								icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
							},
							{
								text1: '21%',
								text2: '人力成本下降',
								icon: require('@/assets/image/resolvePlan/f4_icon_down.png')
							},
							{
								text1: '18%',
								text2: '物流成本下降',
								icon: require('@/assets/image/resolvePlan/f4_icon_down.png')
							}
						]
					}
				]
			},
			floor6: {
				title: '医药三方项目案例',
				projectlist: [
					{
						id: 0,
						img: require('@/assets/image/resolvePlan/kzwl.png'),
						title: '康展物流',
						info: '建设总部、区域、网点三个层次的协同物流网络，上线康展物流云平台、官网、公众号、OFS订单协同管理、TMS运输管理、BMS计费管理、OAS分析决策及IOT物联系统。统一接入订单，智能任务调度，分层执行，精细的核算物流成本与费用。实现物流全过程实时监控与追溯，自动KPI考核数据分析与统计。'
					},
					{
						id: 1,
						img: require('@/assets/image/resolvePlan/omul.png'),
						title: '欧姆龙',
						info: '建设欧姆龙大连、武汉等区域的仓运配一体化物流网络上线OFS订单协同管理、WMS仓储管理、TMS运输管理BMS计费管理、OAS分析决策与EIS设备集成系统。实现从产品入库、出库、暂存、二次包装、礼盒组合等特殊仓储操作；仓储订单、工厂直发订单全生命周期管理；器械UDI码入出库校验、采集、全链路闭环追溯。'
					},
					{
						id: 2,
						img: require('@/assets/image/resolvePlan/shqs.png'),
						title: '上海强生',
						info: '建设武汉、上海两地分仓，并网运营，多仓协同联动，上线OFS订单协同管理、WMS仓储管理、TMS运输管理、BMS计费管理、OAS分析决策与EIS设备集成系统。解决仓储能力不足，从东至西的物流网络覆盖，物流服务履约的问题。'
					}
				]
			}
		}
	},
	// {
	// 	title: '食品生鲜',
	// 	des: '从田间到舌尖的完整供应链平台解决方案',
	// 	path: '/resolvePlan/5',
	// 	mainContent: {
	// 		name: '食品生鲜',
	// 		floor1: {
	// 			title: '食品生鲜供应链物流挑战',
	// 			listData: [
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '上下游数据易孤岛、客户体验差',
	// 					text2: '作业效率低'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '多数商品库存周期短，流通过程中执',
	// 					text2: '行主体多，质量及品控管理难'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '库内作业不同品类商品差异',
	// 					text2: '大，资源利用率低'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '商品损耗高，损耗节点溯源困难'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '存储配送成本高'
	// 				}
	// 			]
	// 		},
	// 		floor2: {
	// 			bg: require('@/assets/image/resolvePlan/f2_bg_4.png'),
	// 			title: '解决方案',
	// 			listData: [
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_41.png'),
	// 					text1: '数智化实时管控',
	// 					text2: '实施物流平台连通上下游、供应链全过程线上管理，全链作业一体化协同运营，从田园到舌尖全链数字化实时管控'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_42.png'),
	// 					text1: '全链化质量监管',
	// 					text2: '质量管控及商品品质保障与作业流程融合，质量问题及时拦截，品质保障实时检测及时预警，及时通知，及时干预与主动稽核'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_43.png'),
	// 					text1: '标准化流程管理',
	// 					text2: '从下游请货到上游备货标准化作业流程管理，全系统应用覆盖，标准化、精细化分类管理，减少商品滞留和损耗'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_44.png'),
	// 					text1: '智能化作业赋能',
	// 					text2: '使用冷库穿梭车、托盘穿梭车、多温层AGV、自动电子秤、手持终端等智能装备，实现物流作业的便捷性，低差错、高效率'
	// 				}
	// 			]
	// 		},
	// 		floor3: {
	// 			title: '方案优势',
	// 			listData: [
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_41.png'),
	// 					text1: '上下游业务信息联动',
	// 					text2: '仓配一体化协同管理'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_42.png'),
	// 					text1: '数据实时共享',
	// 					text2: '全景数字化管控'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_43.png'),
	// 					text1: '质量管理及商品品控与作业流程融合',
	// 					text2: '全过程闭环监控'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_44.png'),
	// 					text1: '高度智能化装备',
	// 					text2: '实现高效率与高吞吐作业'
	// 				}
	// 			]
	// 		},
	// 		floor4: {
	// 			title: '客户案例',
	// 			imgSrc: require('@/assets/image/resolvePlan/f4_4.png'),
	// 			text1: '华鼎食品：打造「从田间到舌间」的物流供应链管理',
	// 			msgBoxList: [
	// 				{
	// 					label: '项目方案',
	// 					content: '打通上下游，从田间到舌间的物流供应链管理，实现采购、仓储、运输、冷链、采购、供应商、门店，集各供应链角色为一体的云仓平台，业务操作流程化规范化，决策分析自动化，全程可视追溯，支持业务量高增长。'
	// 				},
	// 				{
	// 					label: '项目内容',
	// 					content: '上线云平台，包含OMS系统、TMS系统、WMS系统、BMS计费系统、OAS决策分析系统、 IOT物联系统。'
	// 				},
	// 				{
	// 					label: '项目成果',
	// 					type: '2',
	// 					listData: [
	// 						{
	// 							icon: require('@/assets/image/resolvePlan/f4_4_1.png'),
	// 							text: '系统订单实时对接'
	// 						},
	// 						{
	// 							icon: require('@/assets/image/resolvePlan/f4_4_2.png'),
	// 							text: '全环节供应链管理'
	// 						},
	// 						{
	// 							icon: require('@/assets/image/resolvePlan/f4_4_3.png'),
	// 							text: '实现多种采购模式'
	// 						},
	// 						{
	// 							icon: require('@/assets/image/resolvePlan/f4_4_4.png'),
	// 							text: '全移动端作业模式'
	// 						}
	// 					]
	// 				}
	// 			]
	// 		}
	// 	}
	// },
	{
		title: '酒水饮料',
		des: '衔接多销售渠道的信息管理，数字化溯源跟踪',
		path: '/resolvePlan/6',
		mainContent: {
			name: '酒水饮料',
			floor1: {
				title: '酒水行业供应链物流挑战',
				listData: [
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '采购物流量大、易损、过程损耗的控制',
						text2: '与管理物流具有周期性、季节性特点'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '生产物流与生产过程融合度低',
						text2: '需求端与供应端信息不对称，缺乏过程监管'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '源产地发全国流通物流特点，缺乏全局性规划',
						text2: '物流成本高，过程监管不透明'
					},
					{
						bg: require('@/assets/image/resolvePlan/f1_1.png'),
						text1: '逆向物流链条长、责任边界',
						text2: '不清晰、处理周期长'
					}
				]
			},
			floor2: {
				bg: require('@/assets/image/resolvePlan/f2_bg_5.png'),
				programme: require('@/assets/image/resolvePlan/programme2.png'),
				title: '酒水饮料全场景数智物流解决方案',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/systemimage1.png'),
						text1: 'OFS',
						text2: '协同管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage2.png'),
						text1: 'WMS',
						text2: '仓储管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage3.png'),
						text1: 'TMS',
						text2: '运输管理系统'
					},
					{
						icon: require('@/assets/image/resolvePlan/systemimage4.png'),
						text1: 'EIS',
						text2: '设备集成系统'
					}
				]
			},
			floor3: {
				title: '方案优势',
				listData: [
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_51.png'),
						text1: '供应链一体化服务，采运储送等',
						text2: '全闭环打通，标准化物流作业'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_52.png'),
						text1: '多渠道一盘货库存管理',
						text2: '解决各库存渠道相对独立'
					},
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_54.png'),
						text1: '全过程数字化溯源跟踪',
						text2: '确保订单准确交付、质量无忧'
					},
					// {
					//   icon: require("@/assets/image/resolvePlan/f3_icon_54.png"),
					//   text1: '酒水流通全过程数字化溯源跟踪',
					//   text2: '确保订单准确交付、质量无忧'
					// },
					{
						icon: require('@/assets/image/resolvePlan/f3_icon_55.png'),
						text1: '衔接多销售渠道的信息管理体系',
						text2: '柔性调度精准响应'
					}
				]
			},
			floor4: {
				title: '客户案例',
				imgSrc: require('@/assets/image/resolvePlan/f4_5.png'),
				text1: '某酒水公司：打造从生产到消费的端到端供应链物流',
				msgBoxList: [
					{
						label: '项目方案',
						content: '全国成品仓、枢纽仓、区域仓升级及精细化过程仓储管理平台建设干、支、末层级物流网络，可及可视可控和一体协同物流管理平台与上下游系统无缝集成，从产品到终端的全链条安全管理与可追溯。'
					},
					{
						label: '项目内容',
						content: '网络规划设计、物流中心升级、 仓储物流平台小程序、APP 、OMS订单管理系统、 WMS仓储系统、TMS运输管理系统、BMS计费管理系统、 OAS决策分析系统、技术开发集成平。物流管理规范与操作标准。'
					},
					{
						label: '项目成果',
						type: '2',
						listData: [
							{
								icon: require('@/assets/image/resolvePlan/f4_5_1.png'),
								text: '多业态'
							},
							{
								icon: require('@/assets/image/resolvePlan/f4_5_2.png'),
								text: '多业主'
							},
							{
								icon: require('@/assets/image/resolvePlan/f4_5_3.png'),
								text: '多层级'
							},
							{
								icon: require('@/assets/image/resolvePlan/f4_5_4.png'),
								text: '多仓库'
							}
						]
					}
				]
			},
			floor6: {
				title: '酒水饮料项目案例',
				projectlist: [
					{
						id: 0,
						img: require('@/assets/image/resolvePlan/gzmt.png'),
						title: '贵州茅台',
						info: '构建全国物流网络，应用技术开发平台、定制开发OFS、WMS、TMS、BMS、OAS系统。打造白酒行业物流供应链服务平台，支持采购物流、生产物流、流通物流以及逆向物流，满足从计划、调度、执行、评价到分析全过程数智化管理需求。'
					},
					{
						id: 1,
						img: require('@/assets/image/resolvePlan/hhl.png'),
						title: '黄鹤楼',
						info: '构建湖北、安徽、河南、江西华中区域物流网络，集成第三方仓库，应用OFS、WMS、TMS、BMS、OAS系统及运营管理服务，满足ToB、ToC全类型，批发、电商等全业态的成品酒端到端的仓运配一体化的物流服务。'
					},
					{
						id: 2,
						img: require('@/assets/image/resolvePlan/yqsl.png'),
						title: '元气森林',
						info: '构建湖北区域三级层次物流仓配网络，应用OFS、WMS、TMS、BMS、OAS系统及运营管理服务，实现无缝的全渠道订单接入，高自动化的仓储作业，覆盖乡镇一级的运输配送，以及全过程透明的监管追溯。'
					}
				]
			}
		}
	},
	// {
	// 	title: '美妆日化',
	// 	des: '多仓协同管理，智能化调度',
	// 	path: '/resolvePlan/7',
	// 	mainContent: {
	// 		name: '美妆日化',
	// 		floor1: {
	// 			title: '美妆日化供应链物流挑战',
	// 			listData: [
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '订单深度浅、商品关联度高',
	// 					text2: 'SKU种类多、对货位需求大'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '库内周转快、库存深度高',
	// 					text2: '仓储面积与出库及时性需均衡匹配'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '订单出库准确性和配送时效要求高',
	// 					text2: '业务流程越来越复杂'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '线上C端订单多、退货率高',
	// 					text2: '退货处理难度大'
	// 				},
	// 				{
	// 					bg: require('@/assets/image/resolvePlan/f1_1.png'),
	// 					text1: '拣货时效要求高',
	// 					text2: '对智慧仓库效率要求高'
	// 				}
	// 			]
	// 		},
	// 		floor2: {
	// 			bg: require('@/assets/image/resolvePlan/f2_bg_7.png'),
	// 			title: '解决方案',
	// 			listData: [
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_71.png'),
	// 					text1: '数智化管理平台',
	// 					text2: '平台化的多仓协同、仓配联动、精细化货位管理，智能化生产作业和数字化运营管理'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_72.png'),
	// 					text1: '智能化调度系统',
	// 					text2: '全流程策略可配置，实现精细化货位管理，合理库存分配， 高效率作业、上下流程环节协同，订单同步性等智能化控制'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_73.png'),
	// 					text1: '自动化作业赋能',
	// 					text2: '使用立体库、穿梭车、AGV，输送分拣、机械臂、手持终端等智能装备，实现商品存储高密度，物流作业高效率、低差错、高吞吐'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f2_icon_74.png'),
	// 					text1: '模块化定制系统',
	// 					text2: '支持全流程多模式的模块化系统组合方式。同时，满足客户接口定制化需求，易于客户使用和快速对接'
	// 				}
	// 			]
	// 		},
	// 		floor3: {
	// 			title: '方案优势',
	// 			listData: [
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_71.png'),
	// 					text1: '精细化库存分类管理',
	// 					text2: '动态货位管理'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_72.png'),
	// 					text1: '高效提升拆零出库效率',
	// 					text2: '高效提升退货分拣入库效率'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_73.png'),
	// 					text1: '一体智能化拆零设备导入',
	// 					text2: '连续拣选无需波次切换'
	// 				},
	// 				{
	// 					icon: require('@/assets/image/resolvePlan/f3_icon_74.png'),
	// 					text1: '高频商品的快速拣选和订单投递',
	// 					text2: '提升拣选效率'
	// 				}
	// 			]
	// 		},
	// 		floor4: {
	// 			title: '客户案例',
	// 			imgSrc: require('@/assets/image/resolvePlan/f4_7.png'),
	// 			text1: '某化妆品销售公司：打造智能化物流分拣中心',
	// 			msgBoxList: [
	// 				{
	// 					label: '项目方案',
	// 					content: '负责集成总包工作，提供园区总平图设计、物流规划布局、流程设计、方案细化、设备招标选型与安装调试、现场管理、系统设计及开发、系统联调、物流中心整体搬迁、系统上线等全套物流解决方案。'
	// 				},
	// 				{
	// 					label: '项目内容',
	// 					content: '项目整体投入堆垛机立库、四向穿梭车料箱库、AGV等设备，配合WMS、WCS等软件实现多仓库协同一体化调度，全流程精细化管理，提升作业准确度及效率。'
	// 				},
	// 				{
	// 					label: '项目成果',
	// 					type: '1',
	// 					listData: [
	// 						{
	// 							text1: '85%',
	// 							text2: '订单效率提升',
	// 							icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
	// 						},
	// 						{
	// 							text1: '30%',
	// 							text2: '拣选效率提升',
	// 							icon: require('@/assets/image/resolvePlan/f4_icon_up.png')
	// 						}
	// 					]
	// 				}
	// 			]
	// 		}
	// 	}
	// }
]
