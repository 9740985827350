<template>
  <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose" class="down-dialog" :close-on-click-modal="false">
    <div class="bg">
      <img src="../assets/image/resolvePlan/downDialog-bg.png" alt="">
    </div>
    <div class="title"> 登录 </div>
    <el-form :model="formData" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="left">
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="formData.phone" placeholder="请输入您的联系电话" clearable></el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="code">
        <div class="message-box">
          <el-input v-model="formData.code" placeholder="请输入您的短信验证码" clearable></el-input>
          <div class="btn" @click="getCode">
            <div class="text time" v-if="codeTimeFlag"> {{ codeTime }} 秒</div>
            <div class="text" v-else> 获取验证码 </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="btn-list">
          <el-button type="primary" @click="confirm" class="confirm-btn1">登录</el-button>
          <el-button type="success" @click="applicationUse" class="confirm-btn">申请试用</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: 'downDialog',
  data () {
    return {
      limitCodeTime: 60,
      dialogVisible: false,
      codeTime: 60,
      codeTimeFlag: false,
      validateCode: '',// 验证码的验证码
      formData: {
        phone: '',
        code: '',
      },
      rules: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    beforeClose () {
      this.formData = {
        phone: '',
        code: '',
      }
      this.codeTimeFlag = false
      this.codeTime = this.limitCodeTime
      this.validateCode = ''
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.dialogVisible = false
    },
    open () {
      this.dialogVisible = true
    },
    async confirm () {
      try {
        // 手机号查询信息接口：172.31.58.11:7888/tdp/script/Invoke/ApplyUserSelect
        await this.$refs.ruleForm.validate()
        if (!this.codeTimeFlag) {
          this.$message.warning('请获取短信验证码')
          return
        }
        // 验证验证码
        const res = await this.http.service({
          url: '/tdp/msg/smsVerify/valid',
          data: {
            requestId: this.validateCode,
            smsVerify: this.formData.code
          }
        })
        if (res.code !== 200) {
          this.$message.warning(res.message)
          return
        }
        //  查询电话号码
        const userData = await this.http.service({
          url: '/tdp/data/gateway/v2/development_platform_application/select',
          data: {
            "mobile": this.formData.phone
          }
        })
        if (userData.code !== 200) {
          this.$message.warning(userData.message)
          return
        }
        const curphone = this.formData.phone
        //   关闭弹窗
        this.beforeClose()
        if (userData.data[0]) {
          const userInfo = userData.data[0]
          if (curphone) {
            localStorage.setItem('curphone', curphone)
          }
          localStorage.setItem('userInfo', JSON.stringify(userData.data[0]))
          localStorage.setItem('loginStatus', 'true')
          this.$emit('loginSuccess', userInfo)

          //   不是已审核的  就去试用申请
          if (userInfo.apply_status !== '2') {
            const hash = location.hash
            if (hash === '#/applicationUse') {
              window.reload()
            } else {
              this.$router.push('/applicationUse')
            }
          }
        } else {
          this.$router.push('/applicationUse')
        }
      } catch (error) {
      }
    },
    startCodeTime () {
      this.timer = setInterval(() => {
        this.codeTime = this.codeTime - 1
        if (this.codeTime > 0) {
          this.codeTimeFlag = true
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.codeTime = this.limitCodeTime
          this.codeTimeFlag = false
          this.validateCode = ''
        }
      }, 1000)
    },
    applicationUse () {
      this.beforeClose()
      this.$router.push('/applicationUse')
    },
    // //错误信息 "请勿频繁发送,xx秒后再试
    checkTelephone (telephone) {
      var reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/
      if (!reg.test(telephone)) {
        return false
      } else {
        return true
      }
    },

    async getCode () {
      if (this.codeTimeFlag) return
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }

      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }

      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/send',
        data: {
          bizType: 'resolvePlan',
          "templateId": 1,
          verifyLength: 4,
          "to": this.formData.phone,
          verifyValidSecond: this.limitCodeTime,
          verifyResendSecond: this.limitCodeTime,
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      this.$message.success('验证码发送成功')
      //   开始计时
      this.startCodeTime()
      this.validateCode = res.data
    }
  },
}
</script>
<style lang="scss" >
.down-dialog {
  .el-dialog {
    width: 5.72rem !important;
    max-width: 5.72rem !important;
    min-width: 5.72rem !important;

    .title {
      text-align: center;
      height: .39rem;
      font-size: .28rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #0A121F;
      line-height: .33rem;
      margin-bottom: 0.3rem;
    }

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        z-index: 10;
      }
    }

    .el-dialog__body {
      padding: .6rem .8rem;
      position: relative;
      /* background: url('../assets/image/resolvePlan/downDialog-bg.png'); */

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }


      .el-form-item {
        display: flex;
        flex-direction: column;

        /* margin-bottom: 0.15rem; */
        .btn-list {
          display: flex;
        }

        .confirm-btn1,
        .confirm-btn {
          color: #FFFFFF;
          /* height: .47rem; */
          /* background: #0560D6; */
          border-radius: .04rem;
          opacity: 1;
          text-align: center;
          /* line-height: .47rem; */
          cursor: pointer;
        }

        .confirm-btn1 {
          margin-right: 10%;
          background-color: #0560D6;
        }

        .confirm-btn1,
        .confirm-btn {
          width: 45%;
          /* height: .47rem; */
          font-size: .16rem;
        }

        .message-box {
          display: flex;
          align-items: center;

          .btn {
            min-width: 1.2rem;
            max-width: 1.2rem;
            white-space: nowrap;
            height: .47rem;
            background: #FFFFFF;
            border-radius: .04rem;
            opacity: 1;
            border: .01rem solid #0560D6;
            cursor: pointer;
            margin-left: .06rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              height: .21rem;
              font-size: .14rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #0560D6;
              line-height: .21rem;
              text-align: center;

              &.time {
                /* color: orange; */
                font-size: .2rem;
              }
            }
          }

        }

        .el-form-item__label {
          height: .21rem;
          font-size: .16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #616972;
          line-height: .21rem;
          margin-bottom: 0.06rem;
        }

        .el-form-item__content {
          margin-left: 0 !important;

          .el-input__inner {
            height: 0.47rem;
          }

          .el-select {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
