// import Api from '@/common/js/api'

import requestManager from './requestManager'
import Vue from 'vue'


export const request = requestManager.request.bind(requestManager)

// 请求ds
export const gateway = function (options) {
  const pageInfo = Vue.prototype.getMenuInfo()
  const IsLdcs = pageInfo.IsLdcs || '0'
  const IsOperators = pageInfo.IsOperators || '0'
  const defaultOptions = {
    params: {},
    data: {},
    method: 'post',
    url: '/call',
    // dbflag: Api.userInfo.dbflag,
    strategy: true,
    operatorFilter: true,
    ldcFilter: true,
    qs: false,
    loading: true
  }
  const requestOptions = Object.assign(defaultOptions, options || {})
  requestOptions.data = {
    // 数据方案id
    plan_id: requestOptions.ds,
    // 业务入参
    param: options.data || {},
    // 聚合方案
    aggregation_plan: null
  }
  // 分库
  if (requestOptions.dbflag) {
    requestOptions.data.dbflag = requestOptions.dbflag
  }
  // 聚合方案
  if (requestOptions.aggregationPlan) {
    requestOptions.data.aggregation_plan = requestOptions.aggregationPlan
  }
  // 是否走策略
  if (requestOptions.strategy) {
    // requestOptions.data.user_id = Api.userInfo.Staff_Id
    if (requestOptions.operatorFilter && IsOperators === '0') {
      // requestOptions.data.operator_id = Api.userInfo.OperatorId
    } else {
      requestOptions.data.operator_id = ''
    }
    if (requestOptions.ldcFilter && IsLdcs === '0') {
      // requestOptions.data.ldc_id = Api.userInfo.LdcId.indexOf(',') === -1 ? Api.userInfo.LdcId : ''
    } else {
      requestOptions.data.ldc_id = ''
    }
  }
  // 分页
  if (requestOptions.page) {
    requestOptions.data.page_num = options.page
  } else {
    requestOptions.data.page_num = 1
  }
  // 每页条数
  if (requestOptions.pageSize) {
    requestOptions.data.page_size = options.pageSize
  }

  return request(requestOptions)
}

// // 用于表格方法的调用，三方插件只识别code为0，与现在code为200不匹配
// export const gatewayFetchMethod = function (options) {
//   options.code = true
//   return gateway(options)
// }

// export const serviceFetchMethod = function (options) {
//   options.code = true
//   return service(options)
// }
// 请求服务
export const service = function (options) {
  //是否需要将入参格式改为formData
  options.isFormData = options.isFormData ? options.isFormData : false
  if (options.isFormData) {
    options.headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    options.qs = true
  }
  const defaultOptions = {
    params: {},
    data: {},
    method: 'post',
    requestType: '',
    url: '',
    // dbflag: Api.userInfo.dbflag,
    strategy: true,
    isFormData: options.isFormData,
    loading: true
  }
  if (options.params !== undefined) {
    // options.params.created_staff = Api.userInfo.Staff_Id
    // options.params.created_staff_name = Api.userInfo.Staff_Name
    // options.params.operate_mode = 'PC'
    // options.params.ldc_no = Api.userInfo.LdcId
  }
  if (options.data !== undefined) {
    // options.data.created_staff = Api.userInfo.Staff_Id
    // options.data.created_staff_name = Api.userInfo.Staff_Name
    // options.data.operate_mode = 'PC'
    // options.data.ldc_no = Api.userInfo.LdcId
  }

  const requestOptions = Object.assign(defaultOptions, options || {})

  // 分页
  if (requestOptions.page) {
    requestOptions.data.page_num = options.page
  } else {
    requestOptions.data.page_num = 1
  }
  // 每页条数
  if (requestOptions.pageSize) {
    requestOptions.data.page_size = options.pageSize
  }
  // if (requestOptions.strategy) {
  //   requestOptions.data.created_staff = Api.userInfo.Staff_Id
  // }
  return request(requestOptions)
}

// // 请求服务去掉分页
// export const serviceNOpage = function (options) {
//   //是否需要将入参格式改为formData
//   options.isFormData = options.isFormData ? options.isFormData : false
//   if (options.isFormData) {
//     options.headers = {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//     }
//     options.qs = true
//   }
//   const defaultOptions = {
//     params: {},
//     data: {},
//     method: 'post',
//     requestType: '',
//     url: '',
//     // dbflag: Api.userInfo.dbflag,
//     strategy: true,
//     isFormData: options.isFormData,
//     loading: true,
//     isUser: true
//   }
//   if (options.params !== undefined && options.isUser) {
//     // options.params.created_staff = Api.userInfo.Staff_Id
//     // options.params.created_staff_name = Api.userInfo.Staff_Name
//     // options.params.operate_mode = 'PC'
//     // options.params.ldc_no = Api.userInfo.LdcId
//   }
//   if (options.data !== undefined && options.isUser) {
//     // options.data.created_staff = Api.userInfo.Staff_Id
//     // options.data.created_staff_name = Api.userInfo.Staff_Name
//     // options.data.operate_mode = 'PC'
//     // options.data.ldc_no = Api.userInfo.LdcId
//   }

//   const requestOptions = Object.assign(defaultOptions, options || {})

//   // if (requestOptions.strategy) {
//   //   requestOptions.data.created_staff = Api.userInfo.Staff_Id
//   // }
//   return request(requestOptions)
// }

// // 请求权限按钮
// export const getRoleMethods = function (options) {
//   if (!options.funcid) return false
//   const params = {
//     UserId: Api.userInfo.UserId,
//     FuncId: options.funcid
//   }
//   return Api.zuul('session/servlet/GetButtonHave', params, 'get')
// }

// //获取审核流动态按钮
// export const getAuditBtnMethods = function (options) {
//   if (!options.flowChatId) return false
//   const params = {
//     flowChatId: options.flowChatId,
//     personId: Api.userInfo.USERID
//   }
//   return Api.zuul('auditflow/flowchat/getConfig', params)
// }

// 上传
// export const uploadFile = function (options) {
//   options.headers = {
//     'Content-Type': 'multipart/form-data'
//   }
//   options.skip = true
//   const defaultOptions = {
//     params: {},
//     data: {},
//     method: 'post',
//     requestType: '',
//     url: '',
//     dbflag: Api.userInfo.dbflag,
//     strategy: true,
//     isFormData: options.isFormData,
//     loading: true
//   }

//   let formData = new FormData()
//   if (options.params !== undefined) {
//     for (let f in options.params) {
//       if (typeof options.params[f] === 'object' && options.params[f].length) {
//         for (let i = 0; i < options.params[f].length; i++) {
//           formData.append(f, options.params[f][i])
//         }
//       } else {
//         formData.append(f, options.params[f])
//       }
//     }
//     formData.append('created_staff', Api.userInfo.Staff_Id)
//     formData.append('created_staff_name', Api.userInfo.Staff_Name)
//     options.params = formData
//   }
//   if (options.data !== undefined) {
//     for (let f in options.data) {
//       if (typeof options.data[f] === 'object' && options.data[f].length) {
//         for (let i = 0; i < options.data[f].length; i++) {
//           formData.append(f, options.data[f][i])
//         }
//       } else {
//         formData.append(f, options.data[f])
//       }
//     }
//     formData.append('created_staff', Api.userInfo.Staff_Id)
//     formData.append('created_staff_name', Api.userInfo.Staff_Name)
//     options.data = formData
//   }
//   const requestOptions = Object.assign(defaultOptions, options || {})
//   return request(requestOptions)
// }

export default {
  gateway: gateway,
  service: service,
  // serviceNOpage: serviceNOpage,
  // getRoleMethods: getRoleMethods,
  // getAuditBtnMethods: getAuditBtnMethods,
  // gatewayFetchMethod: gatewayFetchMethod,
  // serviceFetchMethod: serviceFetchMethod,
  // uploadFile: uploadFile
}
