import { AxiosResponse } from 'axios'
import qs from 'qs'
import service from './service'
import Vue from 'vue'

const validateStatus = function (response) {
  if (response.status >= 200 && response.status < 300) {
    return true
  }
}
/* eslint-disabled */
class RequestManager {
  constructor () {
    this.apacheList = {}
  }
  getIdentify (options) {
    return `${JSON.stringify(options)}`
  }
  removeApache (identify) {
    if (this.apacheList[identify]) {
      delete this.apacheList[identify]
    }
  }
  fetch (options) {
    options = options || {}
    if (options.qs) {
      options.data = qs.stringify(options.data)
    }
    let loadinginstance = null
    if (options.loading) {
      loadinginstance = Vue.prototype.$loading({
        target: '#app .el-cascader-panel',
        lock: true,
        text: options.loadingText || '加载中'
      })
    }
    return new Promise((resolve, reject) => {
      service(options)
        .then(response => {
          if (loadinginstance) {
            loadinginstance.close()
          }
          const status = validateStatus(response)
          if (status) {
            // 请求成功
            if (response.data && response.data.code !== undefined) {
              if (response.data.code === 200) {
                if (options.code) {
                  response.data.code = 0
                }
                // 格式转换
                response.data.totalPage = +response.data.totalPage
                response.data.totalRow = +response.data.totalRow
              }
            }
            resolve(response.data)
          } else {
            resolve({
              code: response.status,
              message: `http${response.config.url}请求报错, 错误码：${response.status}`,
              data: response.data
            })
          }
        })
        .catch(err => {
          if (loadinginstance) {
            loadinginstance.close()
          }
          reject(err)
        })
    })
  }

  // 拦截重复请求并缓存 避免同时请求多次
  request (options) {
    const identify = this.getIdentify(options)
    // 如果options中设置refresh 则会屏蔽缓存 每次都重新请求数据
    if (!this.apacheList[identify]) {
      // 利用闭包来缓存重复请求的promise
      const requestClosure = () => {
        const request = this.fetch(options)
        return () => {
          return new Promise(resolve => {
            request
              .then(response => {
                resolve(response)
                // 响应之后  删除该请求的缓存
                this.removeApache(identify)
              })
              .catch(e => {
                // 响应之后  删除该请求的缓存
                this.removeApache(identify)
              })
          })
        }
      }
      this.apacheList[identify] = requestClosure()
    }
    return this.apacheList[identify]()
  }
}

export default new RequestManager()
