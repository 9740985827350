<template>
  <div>
    <div class="home_header" :class="{ color01: isChange, }" @mouseover="isChange = true" @mouseout="isChange = false">
      <div class="top">
        <div class="item1" @click="goPage('plat')">开发者平台</div>
        <div class="item2">|</div>
        <div class="item3" @click="goPage('login')">
          {{ loginStatus ? userInfo.apply_name : '登 录' }}</div>
        <div class="item2" v-if="loginStatus">|</div>
        <div class="item1" @click="loginOut" v-if="loginStatus">退 出</div>
      </div>
      <div class="split"></div>
      <div class="bottom">
        <div class="left" @click="toHome">
          <img
            :src="isChange ? require('/public/static/image/home_new/logo_new.png') : require('/public/static/image/home_new/logo_new_1.png')"
            alt="九州云智" />
        </div>

        <div class="center" @mouseout="currentHeaderIndex = 100">
          <div class="item" v-for="(item, index) in headerData" @mouseover="showLine(index)" @click="centerClick(item)">
            <!-- 产品与服务 -->
            <div v-if="item.to === '01'" @mouseover="showProductBox(index)" style="cursor: unset">
              <p>{{ item.des }}</p>
              <div class="line_con">
                <div class="c_line"></div>
              </div>
            </div>
            <!-- 解决方案 -->
            <div v-else-if="item.to === '02'" @mouseover="showResolveBox(index)" style="cursor: unset">
              <div class="route-text">
                <p :class="activePathClass(item)">{{ item.des }}</p>
              </div>
              <div class="line_con">
                <div class="c_line" :data-path="item.path"
                  :class="{ color06: currentHeaderIndex === index || activePathClass(item) }"></div>
              </div>
            </div>
            <!-- 其他 点击标题直接跳转 -->
            <router-link v-else tag="div" :to="item.to" @mouseover.native="close">
              <div class="route-text">
                <img :src="item.hot" v-if="item.hot" class="hot-img">
                <p :class="activePathClass(item)">{{ item.des }}</p>
              </div>
              <div class="line_con">
                <div class="c_line" :data-path="item.path"
                  :class="{ color06: currentHeaderIndex === index || activePathClass(item) }"></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品与服务隐藏栏 -->
    <div class="product" v-show="isProductShow" @mouseleave="close">
      <div class="product-container">
        <div class="left">
          <div>
            <input type="text" placeholder="搜索产品" />
            <img src="~assets/image/home/search.png" alt="" />
          </div>
          <div class="box">
            <div class="item" v-for="(name, index) in lists" @mouseover="changeRight(index)">
              <div class="text">{{ name }}</div>
              <span :class="{ li_active: currentHiddIndex === index }"></span>
            </div>
          </div>
        </div>
        <div class="right" v-for="(content, index) in productData" :key="index" :class="content.class"
          v-show="currentHiddIndex === index">
          <div v-for="(item, i) in content.desData" class="item p_color"
            :class="{ full: content.class === 'r_2' && i === 0 }" :key="i">
            <span @mouseover="changeColor($event, 'p_color')" @mouseout="resetColor"
              @click="toOtherPage(content.path, i)">
              {{ item.title }}
            </span>
            <span v-show="item.hot">HOT</span>
            <h2>{{ item.des }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="resolve_plan" v-show="isResolveShow" @mouseleave="close" @mouseover="isChange = true"
      @mouseout="isChange = false">
      <div class="item" v-for="(item, index) in resolvePlanData">
        <span class="s_color" @mouseover="changeColor($event, 's_color')" @mouseout="resetColor"
          @click="toResolve(index)">{{ item.title }}</span>
        <p>{{ item.des }}</p>
      </div>
    </div>
    <!-- 云市场 -->
    <div class="cloud_market" v-show="isCloudShow" @mouseleave="close">
      <div class="item" v-for="(item, index) in cloudMarketData">
        <span class="c_color" @mouseover="changeColor($event, 'c_color')" @mouseout="resetColor"
          @click="toCloud(index)">{{ item.title }}</span>
        <p>{{ item.des }}</p>
      </div>
    </div>
    <!-- 开发者 -->
    <div class="develop" v-show="isDevelopShow" @mouseleave="close">
      <div class="item" v-for="(item, index) in developData">
        <span class="d_color" @mouseover="changeColor($event, 'd_color')" @mouseout="resetColor"
          @click="toDevelop(index)">{{ item.title }}</span>
        <p>{{ item.des }}</p>
      </div>
    </div>
    <loginDialog ref="loginDialog" @loginSuccess="loginSuccess" />
  </div>
</template>
<script>
import { resolvePlanData } from "../assets/config/resolvePlan"
import loginDialog from './loginDialog.vue'
export default {
  name: "CommonHeader",
  props: {
    isHome: false,
  },
  data () {
    return {
      userInfo: {},
      loginStatus: false,
      isLogin: false,
      isShow: false, //header样式
      currentHeaderIndex: -1,
      lists: ["规划集成", "信息系统", "智能装备"],
      currentHiddIndex: 0,
      activePath: 'home',
      headerData: [
        {
          to: "/",
          des: "首页",
          path: 'home'
        },
        {
          to: "02",
          des: "解决方案",
          path: 'resolvePlan'
        },
        {
          to: "/productServe",
          des: "产品服务",
          path: 'productServe'
        },
        {
          to: "/cloudPlat",
          des: "云平台",
          path: 'cloudPlat',
          hot: require('../assets/image/header/hot.png')
        },
        {
          to: "/caseCenter",
          des: "案例中心",
          path: 'case'
        },
        {
          to: "/news",
          des: "新闻资讯",
          path: 'news'
        },
        {
          to: "/about",
          des: "关于我们",
          path: 'about'
        },
      ],
      productData: [
        {
          title: "物流中心集成",
          des: "",
          path: "/productServe?index=1",
        }
      ],
      // productData: [
      //   {
      //     class: "r_1",
      //     path: "/logisInte",
      //     desData: [
      //       {
      //         title: "物流中心规划",
      //         hot: true,
      //         des: "规划设计一体化的物流中心集成方案",
      //       },
      //       {
      //         title: "智能装备集成",
      //         hot: false,
      //         des: "智能物流中心装备与设施总包集成",
      //       },
      //       {
      //         title: "物流网络规划",
      //         hot: false,
      //         des: "多层次、多仓联运、多段协同网络设计",
      //       },
      //       {
      //         title: "运营管理咨询",
      //         hot: false,
      //         des: "运营全流程的分析诊断与优化改进",
      //       },
      //     ],
      //   },
      //   {
      //     class: "r_2",
      //     path: "/infoTechlo",
      //     desData: [
      //       {
      //         title: "物流应用系统矩阵构建",
      //         hot: false,
      //         des: "OMS、TMS、WMS、WCS，满足物流全过程数字化管控需求",
      //       },
      //       {
      //         title: "物流业务与IT流程融合再造",
      //         hot: true,
      //         des: "物流运营流程与IT流程管理配套",
      //       },
      //       {
      //         title: "企业信息基础设施建设",
      //         hot: false,
      //         des: "MIP、TDP、DDP低代码开发平台与双中台技术架构",
      //       },
      //     ],
      //   },
      //   {
      //     class: "r_3",
      //     path: "/intellectDevice",
      //     desData: [
      //       {
      //         title: "智能装备产品",
      //         hot: true,
      //         des: "穿梭车系列、AGV系列、机械臂系列产品",
      //       },
      //       {
      //         title: "柔性拣选解决方案",
      //         hot: false,
      //         des: "PICK机器人、AGV应用",
      //       },
      //       {
      //         title: "整件无人仓解决方案",
      //         hot: false,
      //         des: "重载穿梭车、机械臂应用",
      //       },
      //       {
      //         title: "零货货到人解决方案",
      //         hot: false,
      //         des: "箱式穿梭车、分拣工作站应用",
      //       },
      //     ],
      //   },
      // ],
      resolvePlanData,
      cloudMarketData: [
        {
          title: "OMS订单管理系统",
          des: "订单全生命周期过程管理与可视追踪",
        },
        {
          title: "TMS运输管理系统",
          des: "实现运输作业过程全场景管理应用",
        },
        {
          title: "WMS仓储管理系统",
          des: "实现平台化的仓储管理要求",
        },
        {
          title: "BMS计费管理系统",
          des: "精细化核算，运用数据指导经营决策",
        },
        {
          title: "OAS运营分析系统",
          des: "构建物流数据资产与应用体系",
        },
      ],

      developData: [
        {
          title: "MIP网络集成平台",
          des: "各信息系统实现快速无缝集成",
        },
        {
          title: "TDP技术开发平台",
          des: "快速构建企业业务中台",
        },
        {
          title: "DDP数据开发平台",
          des: "构建企业数据中台与数据应用系统",
        },
      ],

      isProductShow: false, //产品与服务
      isResolveShow: false, //解决方案
      isCloudShow: false, //云市场
      isDevelopShow: false, //开发者
      isChange: false
    }
  },
  components: {
    loginDialog,
  },
  computed: {
    rightPos () {
      return !this.isLogin ? "left:17.5rem" : ""
    },
  },
  methods: {
    loginOut () {
      localStorage.setItem('userInfo', '')
      localStorage.setItem('curphone', '')
      localStorage.setItem('loginStatus', '')
      window.location.reload()
    },
    activePathClass (item) {
      if (this.activePath === item.path) {
        return 'activePath'
      }
      return false
    },
    centerClick (item) {
      if (item.to && item.to.startsWith('0')) {
        // return true
        this.$router.push(this.resolvePlanData[0].path)
      }
      this.activePath = item.path
    },
    goLogin () {
      this.$refs.loginDialog.open()
    },
    loginSuccess (data) {
      this.loginStatus = true
      this.userInfo = data
    },
    goPage (page) {
      if (page === 'login') {
        this.goLogin()
        return
      }
      window.open('https://tdp.inz56.com')
    },
    //-----------------------header样式更换-----------------------------------
    changeHeader () {
      this.isShow = true
    },

    resetHeader () {
      this.isShow = false
      this.currentHeaderIndex = -1
    },

    //header标题添加下划线
    showLine (index) {
      this.currentHeaderIndex = 100
      this.$nextTick(() => {
        this.currentHeaderIndex = index
      })
    },

    // --------------------------产品与服务-------------------------------------
    //显示
    showProductBox (index) {
      this.isProductShow = true
      //关闭其他
      this.isResolveShow = false
      this.isCloudShow = false
      this.isDevelopShow = false
      this.setHeaderLine(index)
    },

    //右边内容切换
    changeRight (index) {
      this.currentHiddIndex = index
    },

    //跳转
    toOtherPage (path, index) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push({
          path,
          query: { index },
        })
      } else {
        this.$bus.$emit("toContent", index)
      }
    },

    // --------------------------解决方案-------------------------------------
    showResolveBox (index) {
      this.isResolveShow = true
      this.isProductShow = false
      this.isCloudShow = false
      this.isDevelopShow = false
      this.setHeaderLine(index)
    },

    //点击跳转
    toResolve (index) {
      this.centerClick({
        path: 'resolvePlan'
      })
      this.$router.push(this.resolvePlanData[index].path)
      this.isResolveShow = false
    },

    // --------------------------云市场-----------------------------------------

    showCloudBox (index) {
      this.isCloudShow = true
      this.isProductShow = false
      this.isResolveShow = false
      this.isDevelopShow = false
      this.setHeaderLine(index)
    },

    toCloud (index) {
      if (this.$router.currentRoute.path !== "/cloudMarket") {
        this.$router.push({
          path: "/cloudMarket",
          query: { index },
        })
      } else {
        this.$bus.$emit("toContent", index)
      }
    },

    // --------------------------开发者-----------------------------------------

    showDevelopBox (index) {
      this.isDevelopShow = true
      this.isProductShow = false
      this.isResolveShow = false
      this.isCloudShow = false
      this.setHeaderLine(index)
    },

    toDevelop (index) {
      if (this.$router.currentRoute.path !== "/developer") {
        this.$router.push({
          path: "/developer",
          query: { index },
        })
      } else {
        this.$bus.$emit("toContent", index)
      }
    },

    //------------------------方法-----------------------------------------
    //header下划线
    setHeaderLine (index) {
      //去掉其他下划线颜色
      let lines = document.getElementsByClassName("c_line")
      lines.forEach((line) => {
        const dataPath = line.getAttribute('data-path')
        if (dataPath === this.activePath) {
          line.className = "c_line  color06"
        } else {
          line.className = "c_line"
        }
      })
      //加上当前下划线颜色
      let line = document.getElementsByClassName("c_line")[index]
      line.className = "c_line color06"
    },

    //下拉框标题样式
    changeColor (e, className) {
      let spans = [...document.getElementsByClassName(className)]
      spans.forEach((s) => (s.style.color = "#252C3A"))
      e.target.style.color = "#213EAA"
    },

    resetColor (e) {
      e.target.style.color = "#252C3A"
    },

    // 关闭下拉框
    close () {
      let lines = [...document.getElementsByClassName("c_line")]
      lines.map((line) => {
        const dataPath = line.getAttribute('data-path')
        if (dataPath === this.activePath) {
          line.className = "c_line  color06"
        } else {
          line.className = "c_line"
        }
      })
      this.isProductShow = false
      this.isResolveShow = false
      this.isCloudShow = false
      this.isDevelopShow = false
    },

    //------------------------------头部其他按钮---------------------------------

    //点击logo跳转到主页
    toHome () {
      if (this.$router.currentRoute.path === "/") {
        return false
      } else {
        this.$router.push("/")
      }
    },

    // 登录
    login () { },

    // 退出
    exit () {
      this.isLogin = false
    },

    //刷新
    refresh () {
      location.reload()
    },
    init () {
      const path = location.hash.replace('#/', '')
      if (path === '#/' || path === '') {
        this.activePath = 'home'
        return
      }
      const obj = this.headerData.find(item => (item.path === path || path.includes(item.path)))
      if (!obj) return
      this.activePath = obj.path
    }
  },

  created () {
    this.$root.$on('loginSuccess', this.loginSuccess)
    this.init()
    const status = localStorage.getItem('loginStatus')
    if (status === 'true') {
      this.loginStatus = true
    }
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
  },
}
</script>
<style lang="scss" scoped>
.common_header {
  /* position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  width: 100%;
  height: 1.02rem;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  color: #fff;

  .top {
    height: .32rem;
    display: flex;
    align-items: center;
    font-size: .12rem;
    position: absolute;
    right: 2.6rem;

    .item1 {
      cursor: pointer;
    }

    .item2 {
      margin: 0 .16rem;
    }

    .item3 {
      cursor: pointer;
    }
  }

  .split {
    width: 100%;
    height: 1px;
    background: #FFFFFF;
    opacity: 0.2;
  }

  .bottom {
    flex: 1;
    display: flex;
    align-items: center;

    .left {
      margin-left: 2.6rem;
      height: .3rem;
      width: 1.6rem;
      margin-right: 4.13rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .center {
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      right: 2.1rem;

      .item {
        cursor: pointer;
        text-align: center;
        height: 100%;
        width: 1.6rem;
        text-align: center;

        p {
          font-size: 0.18rem;
        }

        .line_con {
          /* margin-top: -0.05rem;  

          .c_line {
            display: inline-block;
            width: 0.64rem;
            height: 0.03rem;
            margin-top: -0.06rem;
            background: transparent;
          }
        }
      }
    }
  } */
}

.home_header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  width: 100%;
  height: 1.02rem;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  color: #fff;

  .top {
    height: .32rem;
    display: flex;
    align-items: center;
    font-size: .12rem;
    position: absolute;
    right: 2.6rem;
    cursor: pointer;

    .item1 {
      padding: 0 .16rem;
      height: .32rem;
      line-height: .32rem;
    }

    /* .item2 {
      margin: 0 .16rem;
    } */

    .item3 {
      padding: 0 .16rem;
      height: .32rem;
      line-height: .32rem;
    }
  }

  .split {
    margin-top: .32rem;
    width: 100%;
    height: 1px;
    background: #FFFFFF;
    opacity: 0.2;
  }

  .bottom {
    flex: 1;
    display: flex;
    align-items: center;

    .left {
      margin-left: 2.6rem;
      height: .3rem;
      width: 1.6rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .center {
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      right: 2.6rem;

      .item {
        cursor: pointer;
        text-align: center;
        margin-left: .7rem;

        .route-text {
          position: relative;
          margin-bottom: .08rem;
        }

        .hot-img {
          position: absolute;
          top: -100%;
          right: -.2rem;
          width: .34rem;
          height: .2rem;
        }

        p {
          font-size: 0.14rem;
        }

        .line_con {
          /* margin-top: -0.05rem; */

          .c_line {
            display: inline-block;
            width: 0.64rem;
            height: 0.03rem;
            margin-top: -0.06rem;
            background: transparent;
          }
        }
      }
    }
  }

  /* .left {
    line-height: 0.8rem;
    margin: 0 2.8rem 0 0.23rem;
    cursor: pointer;

    img {
      width: 1.44rem;
      height: 0.24rem;
    }
  }

 

  .right {
    position: absolute;
    left: 16.5rem;
    opacity: 1;
    display: flex;
    align-items: center;
    height: 100%;

    span {
      height: 0.16rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #c2c9d4;
      line-height: 0.15rem;
      cursor: pointer;
    }

    span:nth-child(n + 1) {
      margin-left: 0.25rem;
    }

    .user {
      position: relative;
    }
  } */
}

//产品与方案
.product {
  position: absolute;
  top: 0.5rem;
  z-index: 3;
  width: 100%;
  background: #ffffff;
  padding: 0.36rem 0 0.1rem;
  min-height: 2.54rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 0.6rem;

  .product-container {
    position: relative;
    display: flex;
  }

  .left {
    width: 2.72rem;
    position: relative;
    margin-top: -0.3rem;

    input {
      width: 100%;
      height: 0.26rem;
      border-bottom: 0.01rem solid #dfe1e6;
    }

    input::-webkit-input-placeholder {
      font-size: 0.14rem;
      font-family: PingFang;
      font-weight: 800;
      color: #979aa3;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 0.16rem;
      height: 0.16rem;
    }

    .box {
      margin-top: 0.22rem;

      .item {
        margin-top: 0.18rem;
        padding-bottom: 0.04rem;
        padding-bottom: 0.04rem;
        cursor: pointer;

        .text {
          //不能给宽度
          font-size: 0.16rem;
          font-weight: 800;
          color: #333333;
          font-family: PingFang SC;
        }

        span {
          display: block;
          width: 0.65rem;
          height: 0.02rem;
          margin-top: 0.03rem;
          text-align: center;
          background: transparent;
        }
      }
    }
  }

  // 相同样式
  .right {
    width: 8.88rem;
    margin-left: 0.6rem;

    .item {
      display: inline-block;
      margin-bottom: 0.4rem;
      width: 50%;
      padding-right: 0.6rem;
      vertical-align: top;
      box-sizing: border-box;

      span:nth-child(1) {
        font-size: 0.16rem;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
      }

      span:nth-child(2) {
        font-size: 0.12rem;
        margin-left: 0.07rem;
        font-family: FZZongYi-M05S;
        font-weight: bold;
        color: #d42020;
      }

      h2 {
        margin-top: 0.11rem;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.23rem;
        color: #999999;
      }

      &.full {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  // 不同样式
  .right {
    .r_1 {
      .item:nth-of-type(odd) {
        padding-right: 0;
      }
    }

    .r_2 {
      .item:nth-child(even) {
        padding-right: 0;
      }
    }
  }
}

//解决方案
.resolve_plan {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 0.5rem;
  z-index: 3;
  width: 100%;
  min-height: 2.54rem;
  background: #ffffff;
  padding: 0.6rem 0 0 2.7rem;

  .item {
    margin-left: 0.7rem;

    span {
      font-size: 0.16rem;
      font-weight: bold;
      color: #252c3a;
      cursor: pointer;
    }

    p {
      width: 3.67rem;
      margin-top: 0.11rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.23rem;
      color: #999999;
    }
  }
}

//云市场
.cloud_market {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 1.02rem;
  z-index: 3;
  width: 100%;
  min-height: 2.54rem;
  background: #ffffff;
  padding: 0.6rem 0 0 2.7rem;

  .item {
    margin-left: 0.7rem;

    span {
      font-size: 0.16rem;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }

    p {
      width: 3.67rem;
      margin-top: 0.11rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.23rem;
      color: #999999;
    }
  }
}

//云市场
.develop {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 0.68rem;
  z-index: 3;
  width: 100%;
  min-height: 2.54rem;
  background: #ffffff;
  padding: 0.6rem 0 0 2.7rem;

  .item {
    margin-left: 0.7rem;

    span {
      font-size: 0.16rem;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }

    p {
      width: 3.67rem;
      margin-top: 0.11rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.23rem;
      color: #999999;
    }
  }
}

//header背景颜色改变
.color01 {
  color: #0A121F;
  background-color: #fff;

  .color06 {
    background: #0560D6 !important;
  }

}

.color06 {
  background: #fff !important;
}

.activePath {
  /* color: #0560D6; */
  font-weight: 700;
}

.li_active {
  border-bottom: 0.02rem solid #d42020 !important;
}
</style>
