<!--  -->
<template>
  <div class="v_footer">
    <div class="v_footer_01">
      <div class="v_footer_left">
        <div class="item1">
          <img :src='require("/public/static/image/home_new/logo_footer.png")' class="logo" alt="九州云智">
        </div>
        <div class="item2">
          <div class="text1">
            咨询热线
          </div>
          <div class="text2">
            <span>400-800-4488转84888</span>
          </div>
        </div>
        <div class="item3">
          <div class="text1">
            湖北省武汉市东西湖区田园大道99号
          </div>
          <div class="text2">
            inz56@jztey.com
          </div>
        </div>
        <div class="item4">
          <el-popover placement="top" width="160" trigger="hover" v-for="item, index in imgList" :key="index">
            <div class="popover-box">
              <img :src="item.icon" />
            </div>
            <div slot="reference" class="popover-box icon">
              <img :src="item.img">
            </div>
          </el-popover>
        </div>
      </div>
      <div class="v_footer_center">
        <div class="item" v-for="item in rightListData">
          <div class="header">
            {{ item.title }}
          </div>
          <div class="container">
            <div class="item" v-for="i in item.data"> {{ i.text }} </div>
          </div>
        </div>
      </div>
    </div>
    <div class="v_footer_02">
      <div class="line line1"></div>
      <div class="container">
        <div class="item1 item"> 友情链接 </div>
        <div class="item" v-for="item in linkList" @click="link(item)"> {{ item.text }} </div>
      </div>
      <div class="line line2"></div>
    </div>
    <span class="bottom_text"> <a href="https://beian.miit.gov.cn" target="_blank">Copyright 2023 www.jzinz.com
        鄂ICP备2021009005号-2
        <div class="icon" style="display: inline-block;">
          <img src="../assets/image/footer/gov-img.png">
        </div> 鄂公网安备 42011202002169
      </a></span>
  </div>
</template>

<script>
import footerConfig from '../assets/config/footerConfig'
export default {
  name: "CommonFooter",
  components: {},
  props: {},
  data () {
    return {
      rightListData: footerConfig.rightListData,
      linkList: footerConfig.linkList,
      imgList: footerConfig.imgList,
      pathData: [
        {
          des: "公司介绍",
          path: "/about",
        },
        {
          des: "发展历程",
          path: "/about",
        },
        {
          des: "招聘信息",
          path: "/recruit",
        },
      ],
      currentIndex: -1,
    }
  },
  methods: {
    link (data) {
      window.open(data.link)
    },
    showBorder (index) {
      this.currentIndex = index
    },

    changeIndex () {
      this.currentIndex = -1
    },

    toOtherPage (index) {
      //到关于页面对应部分
      this.$bus.$emit("toContent", index)
      if (index === 0 || index === 1) {
        this.$router.push({
          path: "/about",
          query: { index },
        })
      }
      //到招聘页面
      else {
        this.$router.push({
          path: "/recruit",
          query: { index },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.popover-box {
  img {
    margin-top: 5%;
    margin-left: 5%;
    width: 90%;
    height: 90%;
  }
}

.v_footer {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 5.44rem;
  background: #030405;
  padding: 0 2.6rem;

  .v_footer_01 {
    position: absolute;
    display: flex;
    top: 0.51rem;
    /* left: 2.55rem;
    width: 14.24rem;
    height: 2.47rem;
    border-bottom: 0.01rem solid #ffffff;
    border-color: rgba(255, 255, 255, 0.09); */

    .v_footer_left {
      .item1 {
        width: 1.6rem;
        height: .3rem;

        img {
          width: 100%;
          height: 100%;
        }

        margin-bottom: .32rem;
      }

      .item2 {
        color: #fff;
        margin-bottom: .32rem;

        .text1 {
          font-size: .16rem;
          margin-bottom: .06rem;
        }

        .text2 {
          font-size: .22rem;
        }
      }

      .item3 {
        font-size: .16rem;
        color: #fff;
        opacity: 0.7;
        margin-bottom: .22rem;

        .text1 {
          margin-bottom: .08rem;
        }

      }

      .item4 {
        display: flex;

        .icon {
          width: .38rem;
          height: .38rem;
          margin-right: .24rem;
          opacity: 0.7;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .icon:hover {
          opacity: 1;
        }
      }
    }

    .v_footer_center {
      color: #fff;
      display: flex;
      padding-top: .21rem;
      margin-left: 3.86rem;

      .item {
        margin-right: .94rem;

        .header {
          font-size: .2rem;
          margin-bottom: .24rem;
        }

        .container {
          opacity: 0.7;

          .item {
            font-size: .14rem;
            margin-bottom: .12rem;
            margin-right: 0;
          }

        }
      }

      /* margin-top: -0.05rem;
      margin-left: 2.3rem;
      border-color: rgba(255, 255, 255, 0.4);
      height: 1.34rem; */
    }
  }

  .v_footer_02 {
    width: 100%;
    position: absolute;
    top: 3.72rem;
    width: 14rem;
    overflow: hidden;
    font-size: .14rem;

    .line {
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.3;
    }

    .line1 {
      margin-bottom: .24rem;
    }

    .container {
      margin-bottom: .24rem;
      display: flex;
      color: #fff;

      .item {
        margin-right: .48rem;
        opacity: 0.7;
        cursor: pointer;
      }

      .item1 {
        margin-right: 1rem;
        opacity: 1;
        cursor: default;
      }
    }

    /* background-color: red; */
  }

  .bottom_text {
    position: absolute;
    bottom: 0.39rem;
    left: 6.02rem;
    width: 7.1rem;
    height: 0.2rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #fff;
    opacity: 0.7;

    a {
      color: #fff;
      opacity: 0.7;
    }
  }
}

.line {
  border-bottom: 0.02rem solid #ffffff !important;
}
</style>
