class Pxiel {
  constructor () {
    this.ratio = 100
    this.standardWidth = window.innerWidth > 750 ? 1920 : 750
  }

  update () {
    this.ratio = (window.innerWidth / this.standardWidth) * 100
    const doc = document.documentElement || document.body
    doc.style.fontSize = `${this.ratio}px`
  }

  init () {
    this.update()
    // window.onresize = () => {
    //    this.update()
    // }
  }

  // dispose() {
  //   window.onresize = null
  // }
}

export default new Pxiel()
