<template>
  <div id="app">
    <common-header :isHome="isHome" v-if="$globalConfig.adaptation"></common-header>
    <router-view v-if="$globalConfig.adaptation" />
    <router-view v-else :key="key" />
    <!-- 侧边栏 -->
    <div class="aslide_bar" v-if="$globalConfig.adaptation">
      <div class="left" v-show="isShowContact" @mouseleave="hiddenContact">
        <div class="item">
          <h2>咨询电话</h2>
          <h3>4008004488-84888</h3>
        </div>
        <div class="item">
          <h2>邮箱</h2>
          <h3>inz56@jztey.com</h3>
        </div>
        <div class="item">
          <h2>公众号</h2>
          <img src="~assets/image/footer/4.png" alt="" />
        </div>
        <span class="iconfont icon-yousanjiaoxing"></span>
      </div>
      <div class="right">
        <div class="top">
          <router-link to="/applicationUse"> <span>申请试用 </span></router-link>
        </div>
        <div class="center">
          <div class="b_1">
            <img src="~assets/image/footer/1.png" alt="" />
          </div>
          <div class="b_2" @mouseover="showContact">
            <span>联系我们</span>
          </div>
        </div>
        <div class="bottom" @click="toTop">
          <img src="~assets/image/home/top.png" alt="" />
        </div>
      </div>
    </div>
    <common-footer v-if="$globalConfig.adaptation"></common-footer>
  </div>
</template>
<script>
import CommonHeader from "@/components/Vheader.vue"
import CommonFooter from "@/components/Vfooter.vue"
export default {
  data () {
    return {
      isHome: false,
      isShowContact: false,
    }
  },
  computed: {
    key () {
      return `${(this.$route.name ? this.$route.name : this.$route)} ${new Date()}`
    }
  },
  methods: {
    //显示联系我们
    showContact () {
      this.isShowContact = true
    },
    hiddenContact () {
      this.isShowContact = false
    },
    //返回顶部
    toTop () {
      const animation = () => {
        if (window.scrollY >= 150) {
          const offsetY = window.scrollY - 150
          window.scrollTo(0, offsetY)
          requestAnimationFrame(animation)
        } else {
          window.scrollTo(0, 0)
        }
      }
      requestAnimationFrame(animation)
    },

    //跳转到到对应内容
    handler (index) {
      this.$nextTick(() => {
        const floors = [...document.getElementsByClassName("floor")]
        const offsets = floors.map((f) => f.offsetTop)
        window.scrollTo(0, offsets[index])
      })
    },
  },
  watch: {
    //监听路由变化
    $route (r) {
      if (this.$globalConfig.adaptation) {
        if (r.name.substring(0, 6) === 'Mobile') {
          this.$router.replace('/')
        } else {
          //是否在主页
          if (r.path === "/") {
            this.isHome = true
          } else {
            this.isHome = false
          }
          //是否传递参数过来
          const index = r.query.index
          if (index !== undefined) {
            this.handler(index)
          }
        }
      } else {
        if (r.name.substring(0, 6) !== 'Mobile') {
          this.$router.replace('/mobileHome')
        }
      }
    },
  },

  mounted () {
    this.$bus.$on("toContent", this.handler)
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
}
</script>

<style lang="scss" >
body {
  width: 100vw;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
}

button {
  background: transparent;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

input,
button,
select,
textarea {
  outline: none;
  border: none;
}

textarea {
  resize: none;
}

body {
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
}

.aslide_bar {
  position: fixed;
  display: flex;
  bottom: 0.7rem;
  right: 0;
  height: 2.93rem;
  z-index: 110;

  .left {
    position: relative;
    width: 2.45rem;
    height: 2.93rem;
    padding: 0.04rem 0 0 0.27rem;
    background: #ffffff;
    border: 0.01rem solid #e4e4e4;
    min-width: 2.45rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item {
      margin-bottom: 0.21rem;

      h2 {
        font-size: 0.14rem;
        font-weight: 400;
        color: #333333;
      }

      h3 {
        font-size: 0.12rem;
        font-weight: 400;
        color: #a6a6a6;
        margin-top: 0.11rem;
      }

      img {
        width: 1.1rem;
        height: 1.1rem;
        margin-top: 0.06rem;
        margin-left: -0.08rem;
      }

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    .icon-yousanjiaoxing {
      position: absolute;
      top: 2.2rem;
      left: 99%;
      color: white;
      font-size: 0.13rem;
    }
  }

  .right {
    width: 0.48rem;
    height: 2.93rem;
    margin-left: 0.1rem;

    .top {
      width: 100%;
      height: 1.15rem;
      padding-top: 0.22rem;
      background: #036ff5;
      margin-bottom: 0.1rem;
      text-align: center;

      span {
        width: 0.15rem;
        display: inline-block;
        font-size: 0.16rem;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .center {
      width: 100%;
      height: 1.66rem;
      box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.44);
      border-radius: 0.02rem;
      background: #ffffff;

      .b_1 {
        width: 0.48rem;
        height: 0.44rem;
        padding: 0.15rem 0 0 0.14rem;
        background: #036ff5;
        border-radius: 0.02rem;

        img {
          width: 0.18rem;
          height: 0.19rem;
        }
      }

      .b_2 {
        text-align: center;
        padding-top: 0.25rem;

        span {
          display: inline-block;
          width: 0.13rem;
          font-size: 0.16rem;
          font-weight: bold;
          color: #333333;
          cursor: pointer;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 0.46rem;
      margin-top: 0.1rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.44);
      border-radius: 0.02rem;
      cursor: pointer;

      img {
        width: 0.22rem;
        height: 0.23rem;
      }
    }
  }
}
</style>
